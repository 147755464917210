var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "views_shopping_mail_featured_modules_teaching_package_unit_major cp",
    },
    [
      _c(
        "div",
        { staticClass: "wrapper" },
        [
          _c("div", { staticClass: "placeholder" }, [
            _vm.picture
              ? _c("img", {
                  ref: "img",
                  staticClass: "img",
                  style: {
                    height: _vm.img_height ? `${_vm.img_height}px` : "100%",
                  },
                  attrs: { src: `${_vm.$img_url}${_vm.picture}`, alt: "" },
                })
              : _vm._e(),
            !_vm.picture
              ? _c(
                  "div",
                  {
                    ref: "no_picture",
                    staticClass:
                      "no_picture_group un_sel bbox d-flex align-center justify-center",
                    style: {
                      height: _vm.img_height ? `${_vm.img_height}px` : "100%",
                    },
                  },
                  [
                    _c("div", { staticClass: "pic_title" }, [
                      _vm._v("\n          " + _vm._s(_vm.title) + "\n        "),
                    ]),
                  ]
                )
              : _vm._e(),
            _c("div", { staticClass: "title mt-10" }, [
              _vm._v(_vm._s(_vm.title)),
            ]),
          ]),
          _c("hover-module", {
            staticClass: "hover_module",
            attrs: { pselected: _vm.pselected, ppackage: _vm.ppackage },
          }),
          0
            ? _c("div", { staticClass: "hover_group bbox" }, [
                _c("div", { staticClass: "info_group" }, [
                  _c("img", {
                    ref: "img2",
                    staticClass: "img",
                    attrs: { src: `${_vm.$img_url}${_vm.picture}`, alt: "" },
                  }),
                  _c("div", { staticClass: "title mt-10" }, [
                    _vm._v(_vm._s(_vm.title)),
                  ]),
                  _c("div", { staticClass: "descript pt-6 pb-10" }, [
                    _vm._v(_vm._s(_vm.content)),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass:
                        "icon_group d-flex align-center justify-space-between pt-10",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "time_group d-flex align-center" },
                        [
                          _c("img", {
                            staticClass: "icon",
                            attrs: {
                              src: require("@/assets/images/shopping_mail/time_icon.png"),
                              alt: "",
                            },
                          }),
                          _c("div", { staticClass: "text" }, [
                            _vm._v(
                              _vm._s(
                                _vm
                                  .$moment(_vm.create_time)
                                  .format("YYYY年MM月DD日")
                              )
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "data_group d-flex align-center" },
                        [
                          _vm.sale
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "item_group d-flex align-center mr-10",
                                },
                                [
                                  _c("img", {
                                    staticClass: "icon mr-4",
                                    attrs: {
                                      src: require("@/assets/images/shopping_mail/sale_icon.png"),
                                      alt: "",
                                    },
                                  }),
                                  _c("div", { staticClass: "text" }, [
                                    _vm._v(_vm._s(_vm.sale)),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                          _vm.readamount
                            ? _c(
                                "div",
                                {
                                  staticClass: "item_group d-flex align-center",
                                },
                                [
                                  _c("img", {
                                    staticClass: "icon mr-4",
                                    attrs: {
                                      src: require("@/assets/images/shopping_mail/view_icon.png"),
                                      alt: "",
                                    },
                                  }),
                                  _c("div", { staticClass: "text" }, [
                                    _vm._v(_vm._s(_vm.readamount)),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                        ]
                      ),
                    ]
                  ),
                  _c("div", { staticClass: "category_group py-10" }, [
                    _vm._v("会展专业 / 会展营销 / 市场营销 "),
                  ]),
                ]),
                _vm._m(0),
              ])
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "payment_group d-flex align-center justify-space-between px-10 py-16",
      },
      [
        _c("div", { staticClass: "price_group" }, [
          _c("div", { staticClass: "price pb-4" }, [_vm._v("¥238000")]),
          _c("div", { staticClass: "hint" }, [
            _vm._v("包含绑定账户数：1-100个"),
          ]),
        ]),
        _c("div", { staticClass: "opeation_group" }, [
          _c("div", { staticClass: "button" }, [_vm._v("咨询购买")]),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }