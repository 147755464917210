<template>
  <div
    class="views_shopping_mail_featured_modules_teaching_package_unit_major cp"
  >
    <div class="wrapper">
      <div class="placeholder">
        <img
          v-if="picture"
          :src="`${$img_url}${picture}`"
          :style="{height: img_height?`${img_height}px`:'100%'}"
          alt=""
          class="img"
          ref="img"
        >
        <div
          v-if="!picture"
          class="no_picture_group un_sel bbox d-flex align-center justify-center"
          ref="no_picture"
          :style="{height: img_height?`${img_height}px`:'100%'}"
        >
          <div class="pic_title">
            {{ title }}
          </div>
        </div>
        <div class="title mt-10">{{title}}</div>
      </div>
      <hover-module
        class="hover_module"
        :pselected="pselected"
        :ppackage="ppackage"
      ></hover-module>
      <div class="hover_group bbox"
        v-if="0"
      >
        <div class="info_group">
          <img
            :src="`${$img_url}${picture}`"
            alt=""
            class="img"
            ref="img2"
          >
          <div class="title mt-10">{{title}}</div>
          <div class="descript pt-6 pb-10">{{content}}</div>
          <div class="icon_group d-flex align-center justify-space-between pt-10">
            <div class="time_group d-flex align-center">
              <img src="~@/assets/images/shopping_mail/time_icon.png" alt="" class="icon">
              <div class="text">{{$moment(create_time).format('YYYY年MM月DD日')}}</div>
            </div>
            <div class="data_group d-flex align-center">
              <div class="item_group d-flex align-center mr-10"
                v-if="sale"
              >
                <img src="~@/assets/images/shopping_mail/sale_icon.png" alt="" class="icon mr-4">
                <div class="text">{{sale}}</div>
              </div>
              <div class="item_group d-flex align-center"
                v-if="readamount"
              >
                <img src="~@/assets/images/shopping_mail/view_icon.png" alt="" class="icon mr-4">
                <div class="text">{{readamount}}</div>
              </div>
            </div>
          </div>
          <div class="category_group py-10">会展专业 / 会展营销 / 市场营销 </div>
        </div>

        <div class="payment_group d-flex align-center justify-space-between px-10 py-16">
          <div class="price_group">
            <div class="price pb-4">¥238000</div>
            <div class="hint">包含绑定账户数：1-100个</div>
          </div>
          <div class="opeation_group">
            <div class="button">咨询购买</div>
          </div>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import HoverModule from './Hover.vue';
export default {
  components: {
    HoverModule,
  },
  props: {
    pselected: {
      type: Object,
      default: undefined,
    },
    ppackage: {
      type: Object,
      default: undefined,
    }
  },
  data(){
    return {
      img_height: undefined,
    }
  },
  computed: {
    picture(){
      const {pselected,ppackage} = this;
      if(pselected){
        return pselected.pic;
      }
      if(ppackage){
        return ppackage.webpic;
      }
      return '';
    },
    title(){
      const {pselected,ppackage} = this;
      if(pselected){
        return pselected.title;
      }
      if(ppackage){
        return ppackage.title;
      }
      return '';
    },
    content(){
      const {pselected,ppackage} = this;
      if(pselected){
        return pselected.ppackage?.content || '';
      }
      if(ppackage){
        return ppackage.content;
      }
      return '';
    },
    create_time(){
      const {pselected,ppackage} = this;
      if(pselected){
        return pselected.createTime;
      }
      if(ppackage){
        return ppackage.createTime;
      }
      return '';
    },
    sale(){
      const {pselected,ppackage} = this;
      if(pselected){
        return pselected.ppackage.sale;
      }
      if(ppackage){
        return ppackage.sale;
      }
      return undefined;
    },
    readamount(){
      const {pselected,ppackage} = this;
      if(pselected){
        return pselected.ppackage.readamount;
      }
      if(ppackage){
        return ppackage.readamount;
      }
      return undefined;
    }
  },
  mounted(){
    this.$nextTick(function(){
      this.init();
    })
  },
  methods: {
    init(){
      this.initSize();
    },
    initSize(){
      let clientWidth
      if (this.$refs.img) {
        clientWidth = this.$refs.img.clientWidth
      }
      if (this.$refs.no_picture) {
        clientWidth = this.$refs.no_picture.clientWidth
      }
      // const { clientWidth } = this.$refs.img
      const calc_height = this.calculativeHeight({
        width: clientWidth
      })
      this.img_height = calc_height
    },
    /**
     * 按照16:9转换高度
     */
    calculativeHeight(params={}){
      const {width} = params;
      const height = (9 * width) / 16;
      return height;
    },
  }

}
/**
 * 288*5 + 20*4 = 1520
 * width 288/1520 = 18.9%
 * margin-right = 1.32%
 */
</script>


<style lang="scss" scoped>
.views_shopping_mail_featured_modules_teaching_package_unit_major{
  .wrapper{
    @include transition;
    transition-delay: 600;
    width: 100%;
    float: left;
    position: relative;
    // overflow: hidden;
    // &:hover{
    //   overflow: unset;
    //   .placeholder{
    //     // opacity: 0;
    //   }
    //   .hover_group{
    //     opacity: 1;
    //     top: -18px;
    //     left: -26px;
    //     right: -26px;
    //     box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.2);
    //     border: 1px solid #E8EEFF;
    //     // transform: scaleY(1);
    //   }
    // }
    .placeholder{
      @include transition;
      position: relative;
      z-index: 2;
      opacity: 1;
    }
    .info_group{
      padding: 10px;
    }
    .img{
      width: 100%;
      display: block;
      border-radius: 4px;
    }
    .no_picture_group{
      width: 100%;
      //display: block;
      border-radius: 4px;
      background-color: #aaa;
      .pic_title{
        font-size: 18px;
        color: #fff;
        width: 100%;
        padding: 0 10px;
        text-align: center;
        word-break: break-all;
      }
    }
    .title{
      @include multi_line_intercept(2);
      width: 100%;
      font-size: 14px;
      color: #333333;
      line-height: 20px;
    }

    .hover_module{
      @include transition(0.1s);
      // transition-delay: 0.3s;
      opacity: 0;
      top:0;
      left: 0;
      transform-origin: top center;
      right: 0;
      position: absolute;
      z-index: 3;
      transform: scaleY(0);
      // display: none;
    }
    &:hover{
      // overflow: unset;
      .hover_module{
        // display: block;
        opacity: 1;
        transform: scaleY(1);
        top: -20px;
        left: -40px;
        right: -40px;
      }
    }
  }
}
</style>

